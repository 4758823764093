'use strict';

module.exports = (function() {

    // private methods

    // public methods

    /**
     * @ngdoc method
     * @name initializeLazysizes
     * @description Configure and run lazysizes
     **/
    var initializeLazysizes = function() {

        /*! modernizr 3.6.0 | MIT */
        // This loads a webp image and checks for a successful load event
        window.webpSupport = false;
        var testImageWebp = new Image();
        testImageWebp.onload = function() {
            webpSupport = (testImageWebp.width == 1);
        };
        testImageWebp.src = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';

        window.lazySizesConfig = {
            preloadAfterLoad: true
        };

        require('lazysizes');

        var bgLoad, regBgUrlEscape;
        regBgUrlEscape = /\(|\)|\s|'/;
        bgLoad = function(url, cb) {
            var img = document.createElement('img');
            img.onload = function() {
                img.onload = null;
                img.onerror = null;
                img = null;
                cb();
            };
            img.onerror = img.onload;

            img.src = url;

            if (img && img.complete && img.onload) {
                img.onload();
            }
        };

        addEventListener('lazybeforeunveil', function(e) {
            if (e.detail.instance != lazySizes) { return; }

            var load, bg;
            if (!e.defaultPrevented) {

                var target = e.target;
                target.preload = 'auto';

                // handle data-background-src
                bg = target.getAttribute('data-background-src');
                if (bg) {
                    //If the bg string ends with a file extension, use ? instead of &
                    bg += ((new RegExp("\\.[A-z]{3,4}$").test(bg)) ? "?" : "&");
                    // If webp support is detected, serve webp
                    bg += (webpSupport ? "format=webp&quality=65" : "format=jpg&quality=80")
                        // Add image to queue
                    e.detail.firesLoad = true;
                    load = function() {
                        target.style.backgroundImage = 'url(' + (regBgUrlEscape.test(bg) ? JSON.stringify(bg) : bg) + ')';
                        e.detail.firesLoad = false;
                        lazySizes.fire(target, '_lazyloaded', {}, true, true);
                    };

                    bgLoad(bg, load);
                }
            }
        }, false);


        console.log("lazysizes initialized");
    };

    // Return an object exposing the public items
    return {
        initialize: initializeLazysizes
    };

}());