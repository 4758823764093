(function (global){
﻿'use strict';

var jquery = require('jquery');
global.jQuery = global.$ = jquery;      // To get the jQuery object in the global scope because Bootstrap 4 doesn't do modules properly
var bootstrap = require('bootstrap');

function _registerEventHandlers() {

    $(document).ready(function () {
        if (document.querySelector(".flickrLink") !== null) {
            $(".flickrLink").each(function (index, element) {
                var link = $(element);
                var href = link.attr("href");
                if (href.indexOf("flic.kr") !== -1) {
                    convertFromBase58(href).then(function (data) {
                        link.attr("href", data);
                    }).catch(function (responseText) {
                        console.log(responseText);
                    });
                }
            });
        }
        if (document.querySelector(".flickrwrap") !== null) {
            waitForElementToDisplay(".flickr-embed-frame", function () {
                $(".flickr-embed-frame").each(function (index, element) {
                    setTitlesandAlt (index, element);
                });
            }, 1000, 9000);
        } 
    });
    
    function waitForElementToDisplay(selector, callback, checkFrequencyInMs, timeoutInMs) {
      var startTimeInMs = Date.now();
      
        (function loopSearch() {
        if (document.querySelector(selector) !== null) {
          callback();
          return;
        }
        else {
          setTimeout(function () {
            if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs)
              return;
            loopSearch();
          }, checkFrequencyInMs);
        }
      })();
    }

    function setTitlesandAlt(index, element) {
       
        var frame = $(element);
        var title = frame.contents().find("head title");
        var photo = frame.contents().find("div.flickr-embed-photo");
        var branding = photo.children("div.branding");
        var href = photo.children("a.slide:first").attr("href");
            
        photo.children("a.previous").attr("title", "Previous image");
        photo.children("a.next").attr("title", "Next image");
        branding.attr("title", "Flickr Branding");
        branding.children("a").attr("title", "Link to Flickr");
        branding.children("a").children("svg").attr("alt", "Flickr Logo");
        
        getTitlefromFlickr(href).then(function (data) {
            frame.attr("title", data);
            title.text(data).html();
        }).catch(function (responseText) {
            console.log(responseText);
        });
    }

    function getTitlefromFlickr(href){
        return new Promise(function(resolve, reject) {
            $.ajax({
                url: '/umbraco/api/flickr/get',
                type: "get",
                data: { url: href },
                success: function(data) {
                    resolve(data);
                },
                error: function(jqXHR, textStatus, responseText) {
                    reject(responseText);
                }
            });
        });
    }

    function convertFromBase58(url) {
        
        var shortUrl = url.split("/");
        var SetID = shortUrl[shortUrl.length-1];

        return new Promise(function(resolve, reject) {
            $.ajax({
                url: '/umbraco/api/flickr/GetConvertFromBase58',
                type: "get",
                async: false,
                data: { encodedSetID: SetID },
                success: function(data) {
                    resolve(data);
                },
                error: function(jqXHR, textStatus, responseText) {
                    reject(responseText);
                }
            });
        });
    }
}

_registerEventHandlers();
}).call(this,typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})