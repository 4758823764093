(function (global){
﻿'use strict';
var jquery = require('jquery');
global.$ = global.jQuery = jquery;      // To get the jQuery object in the global scope because Bootstrap 4 doesn't do modules properly

module.exports = (function () {
    // private methods


    // public methods

    /**
    * @ngdoc method
    * @name initializeScrollToTop
    * @description Setup a scroll to top. Event handlers, etc
    *
    * TODO: Someday when we want to make this more generic, use this cool package https://www.npmjs.com/package/smoothscroll
    **/
    var initializeScrollToTop = function () {
        $('a.backToTop[href*="#"]:not([href="#"])').on('click', function () {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top
                    }, 1000);
                    return false;
                }
            }
        });

        // Back to top
        $(window).scroll(function () {
            if ($(this).scrollTop() > 1040 && !$('a.backToTop').hasClass('btt-in')) {
                $('a.backToTop').addClass('btt-in');
            } else if ($(this).scrollTop() <= 1040 && $('a.backToTop').hasClass('btt-in')) {
                $('a.backToTop').removeClass('btt-in');
            }
        });
    }

    // Return an object exposing the public items
    return {
        initialize: initializeScrollToTop
    };

}());
}).call(this,typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})