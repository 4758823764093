(function (global){
'use strict';

/* theme.js -- Components for theme / master template related items such as Main Navigation, Header interactions, and Footer interactions */

var jquery = require('jquery');
global.jQuery = global.$ = jquery;      // To get the jQuery object in the global scope because Bootstrap 4 doesn't do modules properly
var bootstrap = require('bootstrap');
var topNavigation = require('./components/topNavigation');
var carousels = require('./components/carousel');
var scroll = require('./components/smoothScroll');
var accordions = require("./components/accordion");
var models = require("./models/videomodels");
var video = require('./components/heroVideo');
var flickr = require('./components/flickr');
var lazysizesLoader = require('./components/lazysizesLoader');

// Register event handlers here (like jQuery load)
function _registerEventHandlers() {

    $(document).ready(function () {

        // Setup components
        topNavigation.initialize();
        carousels.initialize();
        scroll.initialize();
        accordions.initialize();
        video.initialize(models);
        lazysizesLoader.initialize();
        console.log("theme.js initialized");

        // Find all external links
        var whitelist = window.location.hostname;
        whitelist = whitelist.replace(/\s/g, "");
        whitelist = whitelist.replace(/,/g, "|");
        whitelist = whitelist.replace(/[\||\s]*$/, "");
        $('a:not([href=""])').each(function () {
            var $this = $(this);
            var url = $this.attr("href");

            if (/^(f|ht)tps?:\/\//i.test(url)) {
                var iswhitelisted = false;
                if (whitelist.length > 0) {
                    var pattern = new RegExp("(" + whitelist + ")", "i");
                    iswhitelisted = pattern.test(url);
                }

                if (this.hostname !== location.hostname &&
                    !iswhitelisted &&
                    $this.hasClass("externalRedirectOK") === false) {
                    $this.addClass("external");
                }
            }
        });

        // We must explicity set max-width on the parent <figure>. The <figcaption> was expanding beyond
        // the parent <figure> on firefox. We use naturalWidth, so that this js works on images that are
        // lazy loaded
        var $image = $('.fwc-figure-image:not(.lazyload)');
        $image.each(function (f) {
            var $this = $(this);
            $this.parents('.fwc-figure').css('max-width', this.naturalWidth);
        });
        document.addEventListener('lazyloaded', function (e) {
            var lazyImage = $(e.target);
            if (lazyImage.hasClass('fwc-figure-image')) {
                $(e.target).closest('.fwc-figure').css('max-width', this.naturalWidth);
            }
        });
    });

}

_registerEventHandlers();

}).call(this,typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})