'use strict';

/* videomodels.js -- Model definitions for video interactions */


/**
* @ngdoc class
* @name VideoPlayer
* @description Object to store the video player with its corresponding image
**/
exports.VideoPlayer = function (item) {
    var self = this;

    if (item === undefined || item === null) {
        self.player = "";
        self.$imageOverlay = "";
        self.imageClickFunction = null;
        self.videoId = "";
    }
    else {
        self.player = item.player;
        self.$imageOverlay = item.$imageOverlay;
        self.imageClickFunction = null;
        self.videoId = item.videoId;
    }
}